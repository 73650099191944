import React, {Component} from 'react';
import {Redirect} from "@reach/router";

class RedirectPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        };

    }

    componentDidMount() {
        this.setState({
            show: true
        });
    }


    render() {

        return(
            // @todo: make redirect dynamic, e.g. data.header.redirectUrl and redirect.md as page type
            <Redirect noThrow to="/lysosomale-stapelingsziekten/algemeen" />
        );
    }
}

export default RedirectPage;
